<template>
  <section>
    <div class="content-header">
      <h2>How can we foster career resilience?</h2>
    </div>
    <div class="content-wrapper">
      <p>The mindsets and resources that can be fostered to promote career resilience include:</p>
      <div class="carousel">
              <!-- <p>Academically tenacious students share 10 qualities. They: </p> -->
              <hooper
                ref="carousel_01"
                class="mb-4"
                :wheelControl="false"
              >
                <slide>
                  <div class="slide">
                    <div>
                      <h4>Curiosity:</h4>
                      <p>Exploring and reflecting on new learning opportunities</p>
                    </div>
                  </div>
                </slide>

                <slide>
                  <div class="slide">
                    <div>
                      <h4>Optimism: </h4>
                      <p>Viewing new opportunities as possible and attainable</p>
                    </div>
                  </div>
                </slide>

                <slide>
                  <div class="slide">
                    <div>
                      <h4>Flexibility and adaptability:</h4>
                      <p>Changing attitudes and circumstances</p>
                    </div>
                  </div>
                </slide>

                 <slide>
                  <div class="slide">
                    <div>
                      <h4>Risk taking:</h4>
                      <p>Taking action in the face of uncertain outcomes</p>
                    </div>
                  </div>
                </slide>

                 <slide>
                  <div class="slide">
                    <div>
                      <h4>Persistence: </h4>
                      <p>Exerting efforts despite setbacks</p>
                    </div>
                  </div>
                </slide>

                <hooper-navigation slot="hooper-addons">
                  <img
                    slot="hooper-prev"
                    src="@/assets/img/_ui/svg/carousel-back.svg"
                    alt="previous"
                    srcset=""
                  >
                  <img
                    slot="hooper-next"
                    src="@/assets/img/_ui/svg/carousel-next.svg"
                    alt="next"
                    srcset=""
                  >
                </hooper-navigation>

                <hooper-pagination slot="hooper-addons"></hooper-pagination>
              </hooper>
            </div>
            <p>We need to keep in mind that it isn’t the responsibility of the student alone to develop and express these mindsets. Students need to have access to and be able to use helpful resources that contribute to their personal agency in the career planning process. They need an environment that enables them to develop resilience. </p>
            <p>Other key components in fostering career resilience include having career and employment services on campus and in the community, as well as supporting social and professional relationships.  </p>
            <p class="lm">Learn more</p>
            <p>You can help foster career resilience by creating a learning environment that recognizes differences. Learn more about how life experiences influence career outcomes, and the importance of personalized and inclusive approaches.</p>
            <ul>
              <li>Centennial College has developed a toolkit to help faculty, instructors and educators encourage a growth mindset and skills with students. While the <a href="https://www.centennialcollege.ca/student-life/career-services-and-co-operative-education/career-resilience/about-the-career-resilience-toolkit/" target="_blank">Career Resilience Toolkit</a> is designed for use by students, it’s a great place for faculty, instructors and educators to start to learn about the <strong class="bold">five components of career resilience</strong> mentioned here.</li>
              <li>Would you like to know more about how career practitioners—as well as faculty, instructors and educators—can engage students in exploratory activities to increase the probability that they will discover unexpected career opportunities? Check out <a href="https://psycnet.apa.org/record/1999-05293-001" target="_blank">Planned happenstance: Constructing unexpected career opportunities</a>.</li>
              <li>Also learn about the role that hope plays in career development in <a href="http://ceric.ca/wp-content/uploads/2012/10/CERIC_Hope-Centered-Career-Research-Final-Report.pdf" target="_blank">Hope-Centered Career Development for University/College Students</a> .</li>
            </ul>
    </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
import {
  Hooper,
  Slide,
  Navigation as HooperNavigation,
  Pagination as HooperPagination
} from 'hooper'
import 'hooper/dist/hooper.css'

export default {
  name: '02',
  components: {
    // SidebarMenu,
    // Accordion,
    Hooper,
    Slide,
    HooperNavigation,
    HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
